import React from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
	constructor(data) {
		super(data);
		this.data = data;
	}

	render() {
		return ( <Layout data={this.data} location={this.props.location}>
                <SEO title="404: Not found"/>
			<div className="container-fluid width835">
				<div className="row">
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn't exist... the sadness.</p>
				</div></div>
            </Layout>
		)
	}
}

export default NotFoundPage


export const pageQuery = graphql`
query NotFoundPageQuery {
  site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
}
`;